import React, { useEffect, useState, Fragment, useContext } from "react";
import { navigate } from 'gatsby'
import { AuthContext, AuthProvider} from "../../components/AuthProvider";
import Layout from "../../components/layout";
import Navbar from "../../components/Navbar"; 
import { CheckCircleIcon } from '@heroicons/react/solid' 
import SuccessPinCodeFormComponent from './SuccessPinCodeFormComponent'

function RegistrationSuccess({location}) { 


     const [username, setUsername] = useState(location && location.state && location.state.email ? location.state.email :'')
 
  return (
    <AuthProvider>
    <Layout location={location}>
      <title>CalPERS Benefits Education Event | CalPERS</title>

      <Navbar location={location}/>
      <div className="overflow-hidden">
      <div className="h-auto bg-cover relative bg-brand-900">
          {/* <StaticImage
            src="../images/calpers-building-sign.jpeg"
            className="hero-image absolute top-0 h-full"
            style={{ opacity: "1", transform: "none" }}
            alt="CalPERS Building"
          /> */}

          <div
            className="relative bg-gray-100"
             
          >
            <div
              className={`max-w-7xl py-8 carousel-content-container flex flex-wrap justify-between relative mx-auto px-4 md:px-6 pb-64`}
            >
              <div className="w-full py-2 md:py-4 md:pr-8 lg:pr-32 relative ">
                <div className="max-w-2xl">
                   
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`max-w-7xl py-8 -mt-64  mx-auto px-4 md:px-6 relative`}>
        <div className={`bg-white rounded-lg shadow-xl`}>
          
            <div className="p-12 py-20">
              <CheckCircleIcon  style={{color:'#689844'}} className="w-20 h-20 text-center mb-2 mx-auto"/>
              <h1 style={{color:'#689844'}} className="text-center font-semibold text-brand-500 text-xl md:text-3xl mx-auto mb-1">
              Registration Successful
              </h1>
              <p className="text-center text-gray-600 pb-2">
              Check your email {username ? `at ${username}` : ''} for a pin code to log in and start enrolling in classes. 
              </p>


              <SuccessPinCodeFormComponent username={username}/> 
             

               
            </div>
         
        </div>
      </div>
    </Layout>
    </AuthProvider>
  );
}

export default RegistrationSuccess;
