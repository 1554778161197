import React from "react";
import { ExternalLinkIcon } from "@heroicons/react/solid";

export default function Footer() {
    const footerLinks = [
        { name: 'Contact', href: 'https://www.calpers.ca.gov/page/contact',},
        { name: 'Privacy Policy',  href: 'https://www.calpers.ca.gov/page/privacy-policy',},
        { name: 'Conditions of Use',  href: 'https://www.calpers.ca.gov/page/conditions-of-use',}, 
      ]

  return (
    <footer className="bg-brand-500">
      <span className="flex items-center flex-wrap justify-center text-center text-white text-sm py-8 px-4">
          {footerLinks.map((link,l)=>(
              <a key={l} href={link.href} target="_blank" className="p-2 px-4 flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500" title="Opens in new window">{link.name} <ExternalLinkIcon className="h-4 w-4 ml-2"/></a>
          ))}
      Copyright <span aria-hidden="true" className="mx-1"> &copy; </span> {new Date().getFullYear() } California Public Employees’ Retirement System (CalPERS)
      </span>
    </footer>
  );
}
 